@use "global" as *;

.p-lowc-point {
  padding-bottom: myrem(64);
}

.p-lowc-point__items {
  margin-top: myrem(84);
}

.p-lowc-point__title {
  margin: 0 auto;
  text-align: center;
}

.p-lowc-point__title {
  h3 {
    text-align: left;
    display: inline-block;
    font-size: myrem(24);
    font-family: $font-tamanegi;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      top: myrem(-15);
      left: myrem(-22);
      background: url(../../../images/circle-red.svg);
      width: myrem(76);
      height: myrem(61);
      background-size: cover;
      z-index: -1;
    }
  }
}

.p-lowc-point__text {
  margin-top: myrem(46);
  font-size: myrem(18);
  line-height: 1.444;
}

.p-lowc-point__img-box {
  margin-top: myrem(32);
  img {
    aspect-ratio: 330 / 236;
    object-fit: cover;
  }
}

.p-lowc-greeting {
  background: $main-color;
  padding-top: myrem(64);
  padding-bottom: myrem(64);
}

.p-lowc-greeting__title {
  margin: 0 auto;
  text-align: center;
  h3{
    font-size: myrem(24);
    text-align: center;
    font-family: $font-tamanegi;
    display: inline-block;
    position: relative;
    z-index: 2;
    &:before {
      position: absolute;
      content: "";
      top: myrem(-18);
      left: myrem(-34);
      background: url(../../../images/circle-black.svg);
      width: myrem(76);
      height: myrem(61);
      background-size: cover;
      z-index: -1;
    }
  }
}

.p-lowc-greeting__text {
  margin-top: myrem(46);
  font-size: myrem(18);
  line-height: 1.444;
}

.p-lowc-greeting__img-box {
  margin-top: myrem(32);
  img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}

@include mq(md) {
  .p-lowc-point__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .p-lowc-point__text-box {
    width: 47.83%;
  }

  .p-lowc-point__title {
    text-align: left;
    font-size: myrem(30);
    h3 {
      br {
        display: none;
      }
    }
  }

  .p-lowc-point__img-box {
    width: 40.75%;
    img {
      aspect-ratio: 489 / 350;
    }
  }

  .p-lowc-greeting__title {
    font-size: myrem(30);
  }

  .p-lowc-greeting__text {
    max-width: myrem(797);
    margin-right: auto;
    margin-left: auto;
  }

  .p-lowc-greeting__img-box {
    max-width: myrem(998);
    margin-right: auto;
    margin-left: auto;
    img {
      aspect-ratio: 16 / 9;
    }
  }
}