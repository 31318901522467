@use "global" as *;

.c-lowm-menutype-title {
  text-align: center;
  font-size: myrem(30);
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-family: $font-tamanegi;
}

.c-lowm-menutype-title::before {
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  content: "";
  bottom: myrem(-10);
  background: url(../../../images/sp/line.png);
  width: 120%;
  height: myrem(10);
  background-size: cover;
}