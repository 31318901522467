@use "global" as *;

#footer {
    background: $main-color;
    text-align: center;
}

.p-footer {
	width:100%;
    text-align: center;
    margin: auto;
}

.p-footer__logo {
    width: myrem(130);
	height: auto;
    margin: 0 auto;
}

.p-footer__list {
    justify-content: space-between;
    text-align: center;
    @include mq(md) {
        display: flex;
        align-items: center;
    }
}


.p-footer__list-item a  {
    padding: myrem(10);
    display: inline-block;
    letter-spacing: 0.1em;
    &:hover {
        color: $black;
    }
    @include mq(md) {
        width: myrem(180);
    }
}

.p-footer__copyright {
    padding: myrem(15);
}
.p-footer__en{
    color: $white;
}