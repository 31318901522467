@use "global" as *;

.p-top {
    background: url(../images/sp/sio-tokusei.jpg)center center / cover;
    aspect-ratio: 375 / 600;
    object-fit: cover;
    display: flex;
	justify-content: space-between;
	align-items: center;
    padding-top: myrem(64);
    @include mq(md) {
        background: url(../images/sio-tokusei.jpg)center center / cover;
        aspect-ratio: 1440 / 830;
        display: block;
        position: relative;
        padding-top: myrem(103);
    }
}

.p-top__title {
    display: inline-block;
    font-family: $font-tamanegi;
    font-size: myrem(40);
    line-height: 1.15;
    text-shadow:  myrem(3) myrem(3) myrem(6) #00000057;
    @include mq(md) {
        font-size: myrem(70);
        line-height: 1.15;
        position: absolute;
        bottom: myrem(120);
    }
}