@use "global" as *;


.p-lowm-drink-menu {
    padding-bottom: myrem(60);
    @include mq(md) {
    padding-bottom: myrem(120);
    }
}

.p-lowm-drink-menu__title {
    font-family: $font-tamanegi;
    color: $main-color;
    font-size: myrem(20);
    @include mq(md) {
        width: 100%;
        font-size: myrem(30);
    }
}

.p-lowm-drink-menu__text {
    margin-top: myrem(15);
}

.p-lowm-topping-menu__items {
    @include mq(md) {
    .p-lowm-drink-menu__text-item {
        width: 100%;
    }
    }
}