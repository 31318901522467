@use "global" as *;

.c-title__red {
    font-size: myrem(24);
    line-height: 1.125;
    font-family: $font-tamanegi;
    @include mq(md) {
        font-size: myrem(30);
        line-height: 1.133;
    }
}

.c-title__red span {
    position: relative;
    z-index: 2;
}

.c-title__red span::before {
    z-index: -1;
    position: absolute;
    content: "";
    background: url(../images/circle-red.svg) center center / cover;
    width: myrem(60);
    height: myrem(60);
    margin-left: myrem(-30);
    top: 50%;
    transform: translateY(-50%);
}

//--------------------黒丸ーーーーーーーーーーーーーーーー
.c-title__black {
    font-size: myrem(24);
    line-height: 1.125;
    font-family: $font-tamanegi;
    @include mq(md) {
        font-size: myrem(30);
        line-height: 1.133;
    }
}

.c-title__black span {
    position: relative;
    z-index: 2;
}

.c-title__black span::before {
    z-index: -1;
    position: absolute;
    content: "";
    background: url(../images/circle-black.svg) center center / cover;
    width: myrem(60);
    height: myrem(60);
    margin-left: myrem(-30);
    top: 50%;
    transform: translateY(-50%);
}