@use "global" as *;

.p-lowm-main-menu__contents {
  margin-top: myrem(64);
  @include mq(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: myrem(64);
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}



.p-lowm-main-menu__text-items {
  @include mq(md) {
    width: 44.18%;
  }
}

.p-lowm-main-menu__text-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-lowm-main-menu__text {
  // font-family: $font-tamanegi;
  color: $main-color;
  font-size: myrem(15);
  margin-top: myrem(20);
  @include mq(md) {
    font-size: myrem(25);
  }
}

.p-lowm-main-menu__text-item:nth-child(n + 2) {
  margin-top: myrem(16);
}

.p-lowm-main-menu__name {
  width: myrem(240);
  @include mq(md) {
    width: myrem(300);
  }
}

.p-lowm-main-menu__name--big {
  font-size: myrem(20);
  font-family: $font-tamanegi;
  @include mq(md) {
    font-size: myrem(30);
  }
  p {
    position: relative;
    display: inline-block;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: myrem(-10);
      background: url(../../../images/sp/line.png);
      width: 100%;
      height: myrem(10);
      background-size: cover;
    }
  }
}

.p-lowm-main-menu__price--big {
  font-size: myrem(20);
  font-family: $font-tamanegi;
  @include mq(md) {
    font-size: myrem(30);
  }
}


.p-lowm-main-menu__img-item {
  margin-top: myrem(32);
  @include mq(md) {
    width: 43.18%;
  }
  img {
    aspect-ratio: 330 / 236;
    object-fit: cover;
    @include mq(md) {
      aspect-ratio: 431/ 313;
    }
  }
}