@use "global" as *;

.p-instagram__title {
    text-align: center;
    padding-top: myrem(22);
    padding-bottom: myrem(47);
}

.p-instagram__img-box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: myrem(32);
}

.p-instagram__img {
    width: calc(100% / 2 );
    img {
        aspect-ratio: 321 / 283;
            object-fit: cover;
    }
    @include mq(md) {
        width: calc(100% / 5 );
    }
}

.p-instagram__btn {
    text-align: center;
}