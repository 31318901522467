@use "global" as *;

#header{
	height: myrem(64);
	width:100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	background: $main-color;
	position: fixed;
	z-index: 999;
	top:0;
	left:0;
	@include mq(md) {
		height: myrem(103);
	}
}

#header.background{
	background: $black;
	transition: all 0.3s;
}

.p-header__logo {
	z-index: 9999;
}
.p-header__logo a{
	display: inline-block;
	width: myrem(100);
	height: myrem(50);
	@include mq(md) {
		width: myrem(130);
		height: myrem(64);
	}
}

.p-header__list {
	display: flex;
	justify-content: center;
	align-items: center;
}

.p-header__list-item {
	padding:myrem(20);
	display: none;
	&:hover {
        color: $black;
    }
	@include mq(md) {
		display: block;
	}
}

.p-sns-item {
	display: inline-block;
}

svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
	width: myrem(30);
	height: auto;
}
