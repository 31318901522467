@use "global" as *;
.c-btn-box {
    text-align: center;
    @include mq(md) {
        text-align: left;
    }
}

.c-btn__red {
    width: 89.09%;
    display: inline-block;
    text-align: center;
    padding: myrem(20);
    position: relative;
    overflow: hidden;
    transition: ease .3s;
    background: $main-color;
    border: myrem(1) solid $main-color;
    &:hover {
        border: myrem(1) solid #fff;
        opacity: 1;
    }
    @include mq(md) {
        width: myrem(350);
    }
}


.c-btn__red span {
    position: relative;
    z-index: 3;
}

.c-btn__red-animation:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: $black;
    width: 100%;
    height: 100%;
    transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
    transform-origin: right top;
}

.c-btn__red-animation:hover:before{
    transform-origin:left top;
    transform:scale(1, 1);
}

//ーーーーーーーーーーー黒ボタンーーーーーーーーーーーーーーーーー

.c-btn__black {
    width: 89.09%;
    display: inline-block;
    text-align: center;
    padding: myrem(20);
    position: relative;
    overflow: hidden;
    transition: ease .3s;
    background: $black;
    border: myrem(1) solid $black;
    &:hover {
        border: myrem(1) solid #fff;
        opacity: 1;
    }
    @include mq(md) {
        width: myrem(350);
    }
}


.c-btn__black span {
    position: relative;
    z-index: 3;
}

.c-btn__black-animation:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: $main-color;
    width: 100%;
    height: 100%;
    transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
    transform-origin: right top;
}

.c-btn__black-animation:hover:before{
    transform-origin:left top;
    transform:scale(1, 1);
}