@use "global" as *;

.p-lowm-side-menu-main__contents {

}

.p-lowm-side-menu-main__cards {
  @include mq(md){
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.p-lowm-side-menu-main__card {
  margin-top: myrem(30);
  @include mq(md){
    max-width: calc(100% / 2 - #{myrem(30)} * 1 / 2);
    width: 100%;
  }
}

.p-lowm-side-menu-main__card:not(:nth-child(2n+1)) {
  @include mq(md) {
      margin-left: myrem(30);
  }
}

.p-lowm-side-menu-main__card-title {

}

.p-lowm-side-menu-main__card-text {

}

.p-lowm-side-menu-main__card-img {
  img {
    width: 100%;
    aspect-ratio: 431/313;
    object-fit: cover;
  }
}

.p-lowm-side-menu {
  padding-top: myrem(64);
  padding-bottom: 20%;
  @include mq(md) {
    padding-top: myrem(128);
    padding-bottom: 10%;
  }
}

.p-lowm-side-menu {
  .p-lowm-main-menu__contents {
    margin-top: myrem(42);
    @include mq(md) {
      &:nth-child(even) {
        flex-direction: unset;
      }
    }
  }

  .p-lowm-main-menu__img-item {
    margin-top: myrem(32);
    width: 74.24%;
    margin-left: auto;
    position: relative;
    @include mq(md) {
      width: 30.18%;
    }
    img {
      object-fit: cover;
    }
    .p-lowm-side-menu__main-img {
      aspect-ratio: 245 / 172;
      @include mq(md) {
        aspect-ratio: 352/ 255;
      }
    }
    .p-lowm-side-menu__absolute-img {
      position: absolute;
      width: 75%;
      left: -33%;
      bottom: -60%;
      z-index: 1;
      aspect-ratio: 180 / 131;
    }
  }
}