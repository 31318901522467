@use "global" as *;

.l-inner {
  width: 100%;
  padding-right: myrem(strip-unit($padding-sp));
  padding-left: myrem(strip-unit($padding-sp));
  margin-right: auto;
  margin-left: auto;
  @include mq(md) {
    max-width: $innerWidth + $padding-pc * 2;
    padding-right: myrem(strip-unit($padding-pc));
    padding-left: myrem(strip-unit($padding-pc));
  }
}

.l-header__inner {
  width: 100%;
  padding-right: myrem(strip-unit($padding-sp));
  padding-left: myrem(strip-unit($padding-sp));
  @include mq(md) {
    padding-right: myrem(strip-unit($padding-pc));
    padding-left: myrem(strip-unit($padding-pc));
  }
}

.l-footer__inner {
  width: 100%;
  padding-right: myrem(strip-unit($padding-sp));
  padding-left: myrem(strip-unit($padding-sp));
  @include mq(md) {
    padding-right: myrem(strip-unit($padding-pc));
    padding-left: myrem(strip-unit($padding-pc));
  }
}