@charset "UTF-8";
@font-face {
  font-family: "tamanegi7";
  src: url("../fonts/tamanegi7v.ttf") format("truetype");
}
html {
  font-size: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 0.9523809524vw;
  }
}
@media (min-width: 1680px) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: Noto Serif JP;
  color: #fff;
}

@media screen and (min-width: 768px) {
  a,
  button {
    transition: 0.3s;
  }
  a:hover,
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-inner {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner {
    max-width: 1680px;
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
}

.l-header__inner {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media screen and (min-width: 768px) {
  .l-header__inner {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
}

.l-footer__inner {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media screen and (min-width: 768px) {
  .l-footer__inner {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
}

.l-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .l-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.c-btn-box {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .c-btn-box {
    text-align: left;
  }
}

.c-btn__red {
  width: 89.09%;
  display: inline-block;
  text-align: center;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  transition: ease 0.3s;
  background: #BF1A21;
  border: 0.0625rem solid #BF1A21;
}
.c-btn__red:hover {
  border: 0.0625rem solid #fff;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .c-btn__red {
    width: 21.875rem;
  }
}

.c-btn__red span {
  position: relative;
  z-index: 3;
}

.c-btn__red-animation:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #0E1317;
  width: 100%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}

.c-btn__red-animation:hover:before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.c-btn__black {
  width: 89.09%;
  display: inline-block;
  text-align: center;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  transition: ease 0.3s;
  background: #0E1317;
  border: 0.0625rem solid #0E1317;
}
.c-btn__black:hover {
  border: 0.0625rem solid #fff;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .c-btn__black {
    width: 21.875rem;
  }
}

.c-btn__black span {
  position: relative;
  z-index: 3;
}

.c-btn__black-animation:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #BF1A21;
  width: 100%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}

.c-btn__black-animation:hover:before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.c-low-mv {
  height: 30svh;
  margin-top: 4rem;
}
@media screen and (min-width: 768px) {
  .c-low-mv {
    height: 45svh;
    margin-top: 6.4375rem;
  }
}

.c-low-mv__contents {
  background: url(../../../images/sio-tokusei.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.p-lowc-mv .c-low-mv__contents {
  background: url(../../../images/sp/commitment-top.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-low-mv__text-box {
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "tamanegi7", sans-serif;
}

.p-lowm-main-menu__price {
  color: #BF1A21;
  font-size: 1.125rem;
}

.c-low-mv__title {
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .c-low-mv__title {
    font-size: 3.125rem;
  }
}

.c-lowm-menutype-title {
  text-align: center;
  font-size: 1.875rem;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-family: "tamanegi7", sans-serif;
}

.c-lowm-menutype-title::before {
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  content: "";
  bottom: -0.625rem;
  background: url(../../../images/sp/line.png);
  width: 120%;
  height: 0.625rem;
  background-size: cover;
}

.c-title__red {
  font-size: 1.5rem;
  line-height: 1.125;
  font-family: "tamanegi7", sans-serif;
}
@media screen and (min-width: 768px) {
  .c-title__red {
    font-size: 1.875rem;
    line-height: 1.133;
  }
}

.c-title__red span {
  position: relative;
  z-index: 2;
}

.c-title__red span::before {
  z-index: -1;
  position: absolute;
  content: "";
  background: url(../images/circle-red.svg) center center/cover;
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1.875rem;
  top: 50%;
  transform: translateY(-50%);
}

.c-title__black {
  font-size: 1.5rem;
  line-height: 1.125;
  font-family: "tamanegi7", sans-serif;
}
@media screen and (min-width: 768px) {
  .c-title__black {
    font-size: 1.875rem;
    line-height: 1.133;
  }
}

.c-title__black span {
  position: relative;
  z-index: 2;
}

.c-title__black span::before {
  z-index: -1;
  position: absolute;
  content: "";
  background: url(../images/circle-black.svg) center center/cover;
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1.875rem;
  top: 50%;
  transform: translateY(-50%);
}

.p-access__top-img {
  padding-top: 4rem;
  width: 100%;
  background: url(../../../images/sp/shop.png) center center/cover;
  aspect-ratio: 375/132;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-access__top-img {
    padding-top: 0;
    aspect-ratio: 1440/308;
    object-fit: cover;
  }
}

.p-access__title {
  text-align: center;
}

.p-access__box {
  padding-top: 2.875rem;
}
@media screen and (min-width: 768px) {
  .p-access__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 6.375rem 0rem 6.375rem;
    flex-direction: row-reverse;
  }
}

.iframe-wrap {
  width: 100%;
  aspect-ratio: 330/195;
  object-fit: cover;
  position: relative;
}
@media screen and (min-width: 768px) {
  .iframe-wrap {
    width: 60%;
    aspect-ratio: 623/368;
    object-fit: cover;
  }
}
.iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .p-access__text-box {
    width: 29.25%;
  }
}

.p-access__address {
  padding-top: 2rem;
}
@media screen and (min-width: 768px) {
  .p-access__address {
    padding-top: 0;
  }
}

.p-access__information {
  padding-top: 1.125rem;
}
@media screen and (min-width: 768px) {
  .p-access__information {
    padding-top: 1.75rem;
  }
}

.p-concept__title {
  text-align: center;
  padding-top: 3.3125rem;
  padding-bottom: 2.8125rem;
}

.p-concept__text {
  padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .p-concept__img {
    width: 44.66%;
  }
  .p-concept__text-box {
    width: 48.83%;
    height: 18.75rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .p-concept__title {
    padding: 0;
    text-align: left;
  }
  .p-concept__text {
    padding: 0;
  }
}
#footer {
  background: #BF1A21;
  text-align: center;
}

.p-footer {
  width: 100%;
  text-align: center;
  margin: auto;
}

.p-footer__logo {
  width: 8.125rem;
  height: auto;
  margin: 0 auto;
}

.p-footer__list {
  justify-content: space-between;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-footer__list {
    display: flex;
    align-items: center;
  }
}

.p-footer__list-item a {
  padding: 0.625rem;
  display: inline-block;
  letter-spacing: 0.1em;
}
.p-footer__list-item a:hover {
  color: #0E1317;
}
@media screen and (min-width: 768px) {
  .p-footer__list-item a {
    width: 11.25rem;
  }
}

.p-footer__copyright {
  padding: 0.9375rem;
}

.p-footer__en {
  color: #fff;
}

#g-nav {
  position: fixed;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0E1317;
  transition: all 0.3s;
}
@media screen and (min-width: 768px) {
  #g-nav {
    display: none;
  }
}

#g-nav.panelactive {
  opacity: 1;
  z-index: 100;
}

#g-nav.panelactive #g-nav-list {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

#g-nav ul {
  position: relative;
  display: none;
  z-index: 100;
  top: 20%;
}

#g-nav.panelactive ul {
  display: block;
}

#g-nav li {
  list-style: none;
  text-align: center;
}

#g-nav li a {
  text-decoration: none;
  padding: 0.625rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
}
#g-nav li a:hover {
  color: #BF1A21;
}

.p-hamburger {
  position: fixed;
  z-index: 9999;
  top: 0.625rem;
  right: 0.625rem;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-hamburger {
    display: none;
  }
}

.p-hamburger span {
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  left: 0.875rem;
  height: 0.1875rem;
  border-radius: 0.125rem;
  background-color: #fff;
  width: 45%;
}

.p-hamburger span:nth-of-type(1) {
  top: 0.9375rem;
}

.p-hamburger span:nth-of-type(2) {
  top: 1.4375rem;
}

.p-hamburger span:nth-of-type(3) {
  top: 1.9375rem;
}

.p-hamburger.active span:nth-of-type(1) {
  top: 1.125rem;
  left: 1.125rem;
  transform: translateY(0.375rem) rotate(-45deg);
  width: 30%;
}

.p-hamburger.active span:nth-of-type(2) {
  opacity: 0;
}

.p-hamburger.active span:nth-of-type(3) {
  top: 1.875rem;
  left: 1.125rem;
  transform: translateY(-0.375rem) rotate(45deg);
  width: 30%;
}

#header {
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: #BF1A21;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  #header {
    height: 6.4375rem;
  }
}

#header.background {
  background: #0E1317;
  transition: all 0.3s;
}

.p-header__logo {
  z-index: 9999;
}

.p-header__logo a {
  display: inline-block;
  width: 6.25rem;
  height: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-header__logo a {
    width: 8.125rem;
    height: 4rem;
  }
}

.p-header__list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-header__list-item {
  padding: 1.25rem;
  display: none;
}
.p-header__list-item:hover {
  color: #0E1317;
}
@media screen and (min-width: 768px) {
  .p-header__list-item {
    display: block;
  }
}

.p-sns-item {
  display: inline-block;
}

svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
  width: 1.875rem;
  height: auto;
}

.p-instagram__title {
  text-align: center;
  padding-top: 1.375rem;
  padding-bottom: 2.9375rem;
}

.p-instagram__img-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}

.p-instagram__img {
  width: 50%;
}
.p-instagram__img img {
  aspect-ratio: 321/283;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-instagram__img {
    width: 20%;
  }
}

.p-instagram__btn {
  text-align: center;
}

.p-lowc-point {
  padding-bottom: 4rem;
}

.p-lowc-point__items {
  margin-top: 5.25rem;
}

.p-lowc-point__title {
  margin: 0 auto;
  text-align: center;
}

.p-lowc-point__title h3 {
  text-align: left;
  display: inline-block;
  font-size: 1.5rem;
  font-family: "tamanegi7", sans-serif;
  position: relative;
}
.p-lowc-point__title h3:before {
  position: absolute;
  content: "";
  top: -0.9375rem;
  left: -1.375rem;
  background: url(../../../images/circle-red.svg);
  width: 4.75rem;
  height: 3.8125rem;
  background-size: cover;
  z-index: -1;
}

.p-lowc-point__text {
  margin-top: 2.875rem;
  font-size: 1.125rem;
  line-height: 1.444;
}

.p-lowc-point__img-box {
  margin-top: 2rem;
}
.p-lowc-point__img-box img {
  aspect-ratio: 330/236;
  object-fit: cover;
}

.p-lowc-greeting {
  background: #BF1A21;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-lowc-greeting__title {
  margin: 0 auto;
  text-align: center;
}
.p-lowc-greeting__title h3 {
  font-size: 1.5rem;
  text-align: center;
  font-family: "tamanegi7", sans-serif;
  display: inline-block;
  position: relative;
  z-index: 2;
}
.p-lowc-greeting__title h3:before {
  position: absolute;
  content: "";
  top: -1.125rem;
  left: -2.125rem;
  background: url(../../../images/circle-black.svg);
  width: 4.75rem;
  height: 3.8125rem;
  background-size: cover;
  z-index: -1;
}

.p-lowc-greeting__text {
  margin-top: 2.875rem;
  font-size: 1.125rem;
  line-height: 1.444;
}

.p-lowc-greeting__img-box {
  margin-top: 2rem;
}
.p-lowc-greeting__img-box img {
  aspect-ratio: 16/9;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .p-lowc-point__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .p-lowc-point__items:nth-child(even) {
    flex-direction: row-reverse;
  }
  .p-lowc-point__text-box {
    width: 47.83%;
  }
  .p-lowc-point__title {
    text-align: left;
    font-size: 1.875rem;
  }
  .p-lowc-point__title h3 br {
    display: none;
  }
  .p-lowc-point__img-box {
    width: 40.75%;
  }
  .p-lowc-point__img-box img {
    aspect-ratio: 489/350;
  }
  .p-lowc-greeting__title {
    font-size: 1.875rem;
  }
  .p-lowc-greeting__text {
    max-width: 49.8125rem;
    margin-right: auto;
    margin-left: auto;
  }
  .p-lowc-greeting__img-box {
    max-width: 62.375rem;
    margin-right: auto;
    margin-left: auto;
  }
  .p-lowc-greeting__img-box img {
    aspect-ratio: 16/9;
  }
}
.p-lowm-drink-menu {
  padding-bottom: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-drink-menu {
    padding-bottom: 7.5rem;
  }
}

.p-lowm-drink-menu__title {
  font-family: "tamanegi7", sans-serif;
  color: #BF1A21;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-drink-menu__title {
    width: 100%;
    font-size: 1.875rem;
  }
}

.p-lowm-drink-menu__text {
  margin-top: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .p-lowm-topping-menu__items .p-lowm-drink-menu__text-item {
    width: 100%;
  }
}

.p-lowm-topping-menu__title-box {
  text-align: center;
}

.p-lowm-side-menu__title-box {
  text-align: center;
}

.p-lowm-menu-body {
  background: #0E1317;
}

.p-lowm-main-menu__contents {
  margin-top: 4rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
  }
  .p-lowm-main-menu__contents:nth-child(even) {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-lowm-main-menu__text-items {
    width: 44.18%;
  }
}

.p-lowm-main-menu__text-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-lowm-main-menu__text {
  color: #BF1A21;
  font-size: 0.9375rem;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__text {
    font-size: 1.5625rem;
  }
}

.p-lowm-main-menu__text-item:nth-child(n+2) {
  margin-top: 1rem;
}

.p-lowm-main-menu__name {
  width: 15rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__name {
    width: 18.75rem;
  }
}

.p-lowm-main-menu__name--big {
  font-size: 1.25rem;
  font-family: "tamanegi7", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__name--big {
    font-size: 1.875rem;
  }
}
.p-lowm-main-menu__name--big p {
  position: relative;
  display: inline-block;
}
.p-lowm-main-menu__name--big p:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -0.625rem;
  background: url(../../../images/sp/line.png);
  width: 100%;
  height: 0.625rem;
  background-size: cover;
}

.p-lowm-main-menu__price--big {
  font-size: 1.25rem;
  font-family: "tamanegi7", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__price--big {
    font-size: 1.875rem;
  }
}

.p-lowm-main-menu__img-item {
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__img-item {
    width: 43.18%;
  }
}
.p-lowm-main-menu__img-item img {
  aspect-ratio: 330/236;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-lowm-main-menu__img-item img {
    aspect-ratio: 431/313;
  }
}

.p-lowm-top-body {
  background: #0E1317;
}

@media screen and (min-width: 768px) {
  .p-lowm-top-concept-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-lowm-top-menu-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .p-lowm-side-menu-main__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.p-lowm-side-menu-main__card {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-side-menu-main__card {
    max-width: calc(50% - 1.875rem * 1 / 2);
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-lowm-side-menu-main__card:not(:nth-child(2n+1)) {
    margin-left: 1.875rem;
  }
}

.p-lowm-side-menu-main__card-img img {
  width: 100%;
  aspect-ratio: 431/313;
  object-fit: cover;
}

.p-lowm-side-menu {
  padding-top: 4rem;
  padding-bottom: 20%;
}
@media screen and (min-width: 768px) {
  .p-lowm-side-menu {
    padding-top: 8rem;
    padding-bottom: 10%;
  }
}

.p-lowm-side-menu .p-lowm-main-menu__contents {
  margin-top: 2.625rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-side-menu .p-lowm-main-menu__contents:nth-child(even) {
    flex-direction: unset;
  }
}
.p-lowm-side-menu .p-lowm-main-menu__img-item {
  margin-top: 2rem;
  width: 74.24%;
  margin-left: auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-lowm-side-menu .p-lowm-main-menu__img-item {
    width: 30.18%;
  }
}
.p-lowm-side-menu .p-lowm-main-menu__img-item img {
  object-fit: cover;
}
.p-lowm-side-menu .p-lowm-main-menu__img-item .p-lowm-side-menu__main-img {
  aspect-ratio: 245/172;
}
@media screen and (min-width: 768px) {
  .p-lowm-side-menu .p-lowm-main-menu__img-item .p-lowm-side-menu__main-img {
    aspect-ratio: 352/255;
  }
}
.p-lowm-side-menu .p-lowm-main-menu__img-item .p-lowm-side-menu__absolute-img {
  position: absolute;
  width: 75%;
  left: -33%;
  bottom: -60%;
  z-index: 1;
  aspect-ratio: 180/131;
}

.p-lowm-topping-menu {
  padding-top: 4rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-topping-menu {
    padding-top: 8rem;
  }
}

.p-lowm-topping-menu__items {
  margin-top: 2.625rem;
}
@media screen and (min-width: 768px) {
  .p-lowm-topping-menu__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .p-lowm-topping-menu__items .p-lowm-topping-menu__text-item {
    width: 45%;
  }
}

.p-menu {
  background: #BF1A21;
}

.p-menu__img-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.p-menu__img-big {
  width: 100%;
}
.p-menu__img-big img {
  aspect-ratio: 160/141;
  object-fit: cover;
}

.p-menu__img-small {
  width: 100%;
}
.p-menu__img-small img {
  aspect-ratio: 160/141;
  object-fit: cover;
}

.p-menu__title {
  text-align: center;
  padding-top: 2.875rem;
  padding-bottom: 2.875rem;
}

.p-menu__text {
  padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .p-menu__img-box {
    width: 50%;
  }
  .p-menu__text-box {
    width: 39.25%;
  }
  .p-menu__img-big {
    width: 100%;
    width: 48%;
  }
  .p-menu__img-big img {
    aspect-ratio: 513/434;
    object-fit: cover;
  }
  .p-menu__img-small {
    margin-top: 15%;
    width: 48%;
  }
  .p-menu__img-small img {
    aspect-ratio: 321/283;
    object-fit: cover;
  }
  .p-menu__title {
    text-align: left;
    padding-top: 6rem;
    padding-bottom: 2.875rem;
  }
  .p-menu__text {
    padding-bottom: 2rem;
  }
  .p-menu__btn {
    margin-top: 3rem;
  }
}
.p-top {
  background: url(../images/sp/sio-tokusei.jpg) center center/cover;
  aspect-ratio: 375/600;
  object-fit: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4rem;
}
@media screen and (min-width: 768px) {
  .p-top {
    background: url(../images/sio-tokusei.jpg) center center/cover;
    aspect-ratio: 1440/830;
    display: block;
    position: relative;
    padding-top: 6.4375rem;
  }
}

.p-top__title {
  display: inline-block;
  font-family: "tamanegi7", sans-serif;
  font-size: 2.5rem;
  line-height: 1.15;
  text-shadow: 0.1875rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.3411764706);
}
@media screen and (min-width: 768px) {
  .p-top__title {
    font-size: 4.375rem;
    line-height: 1.15;
    position: absolute;
    bottom: 7.5rem;
  }
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}