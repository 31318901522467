@use "global" as *;

.p-lowm-topping-menu {
  padding-top: myrem(64);
  @include mq(md) {
    padding-top: myrem(128);
  }
}

.p-lowm-topping-menu__items {
  margin-top: myrem(42);
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .p-lowm-topping-menu__text-item {
      width: 45%;
    }
  }
}