@use "global" as *;


.p-lowm-topping-menu__title-box {
  text-align: center;
}

.p-lowm-side-menu__title-box {
  text-align: center;
}

.p-lowm-menu-body {
  background: $black;
}