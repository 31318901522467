@use "global" as *;

.p-access__top-img {
    padding-top: myrem(64);
    width: 100%;
    background: url(../../../images/sp/shop.png)center center / cover;
    aspect-ratio: 375 / 132;
    object-fit: cover;
    @include mq(md) {
        padding-top: 0;
        aspect-ratio: 1440 / 308;
        object-fit: cover;
    }
}

.p-access__title {
    text-align: center;
}

.p-access__box {
    padding-top: myrem(46);
    @include mq(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: myrem(64) myrem(102) myrem(0) myrem(102);
        flex-direction: row-reverse;
    }
}

.iframe-wrap {
    width: 100%;
    aspect-ratio: 330 / 195;
    object-fit: cover;
    position: relative;
    @include mq(md) {
        width: 60%;
        aspect-ratio: 623 / 368;
        object-fit: cover;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.p-access__text-box {
    @include mq(md) {
        width: 29.25%;
    }
}

.p-access__address {
    padding-top: myrem(32);
    @include mq(md) {
        padding-top: 0;
    }
}
.p-access__information {
    padding-top: myrem(18);
    @include mq(md) {
        padding-top: myrem(28);
    }
}