@use "global" as *;


.p-lowm-top-body {
    background: $black;
}

.p-lowm-top-concept-box {
    @include mq(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
    }
}

.p-lowm-top-menu-box {
    @include mq(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}