@use "global" as *;

.c-low-mv {
  height: 30svh;
  margin-top: myrem(64);
  @include mq(md) {
    height: 45svh;
    margin-top: myrem(103);
  }
}

.c-low-mv__contents {
  background: url(../../../images/sio-tokusei.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.p-lowc-mv {
  .c-low-mv__contents {
    background: url(../../../images/sp/commitment-top.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.c-low-mv__text-box {
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: $font-tamanegi;
}

.p-lowm-main-menu__price {
  color: $main-color;
  font-size: myrem(18);
}

.c-low-mv__title {
  font-size: myrem(32);
  @include mq(md) {
    font-size: myrem(50);
  }
}