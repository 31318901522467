@use "global" as *;

#g-nav{
    position:fixed;
	z-index: -1;
	opacity: 0;
	top:0;
    left: 0;
	width:100%;
    height: 100vh;
	background: $black;
	transition: all 0.3s;
    @include mq(md) {
        display: none;
    }
}

#g-nav.panelactive{
	opacity: 1;
	z-index:100;
}


#g-nav.panelactive #g-nav-list{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

#g-nav ul {
    position: relative;
    display: none;
    z-index: 100;
    top: 20%;
}

#g-nav.panelactive ul {
    display: block;
}


#g-nav li{
	list-style: none;
    text-align: center;
}

#g-nav li a{
	text-decoration: none;
	padding: myrem(10);
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: bold;
    &:hover {
        color: $main-color;
    }
}

.p-hamburger{
	position:fixed;
    z-index: 9999;
	top: myrem(10);
	right: myrem(10);
	cursor: pointer;
    width: myrem(50);
    height: myrem(50);
    display: block;
    @include mq(md) {
        display: none;
    }
}

.p-hamburger span{
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: myrem(14);
    height: myrem(3);
    border-radius: myrem(2);
	background-color: $white;
    width: 45%;
}

.p-hamburger span:nth-of-type(1) {
	top: myrem(15);
}

.p-hamburger span:nth-of-type(2) {
	top: myrem(23);
}

.p-hamburger span:nth-of-type(3) {
	top: myrem(31);
}

.p-hamburger.active span:nth-of-type(1) {
    top: myrem(18);
    left: myrem(18);
    transform: translateY(myrem(6)) rotate(-45deg);
    width: 30%;
}

.p-hamburger.active span:nth-of-type(2) {
	opacity: 0;
}

.p-hamburger.active span:nth-of-type(3){
    top: myrem(30);
    left: myrem(18);
    transform: translateY(myrem(-6)) rotate(45deg);
    width: 30%;
}