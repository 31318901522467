@use 'sass:math';

// インナー幅
$innerWidth: 1440px;

// 余白
$padding-pc: 120px;
$padding-sp: 20px;

// フォント
$font-main: Noto Serif JP;

@font-face {
  font-family: "tamanegi7";
  src: url("../fonts/tamanegi7v.ttf") format("truetype");
}
$font-tamanegi: "tamanegi7" ,sans-serif;

// 色指定
$main-color: #BF1A21;
$sub-color: #F23F46;
$black: #0E1317;
$white: #fff;

// ブレイクポイント
$breakpoints: (
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1440,
);

// メディアクエリ
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
);

// メディアクエリを "@include mq(){}" で書くことができる
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}