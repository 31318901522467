@use "global" as *;

.p-concept__title {
    text-align: center;
    padding-top: myrem(53);
    padding-bottom: myrem(45);
}

.p-concept__text {
    padding-bottom: myrem(32);
}


@include mq(md) {
    .p-concept__img {
        width: 44.66%;
    }

    .p-concept__text-box {
        width: 48.83%;
        height: myrem(300);
        display:flex;
        flex-flow: column;
        justify-content:space-between;
    }

    .p-concept__title {
        padding: 0;
        text-align: left;
    }

    .p-concept__text {
        padding: 0;
    }
}