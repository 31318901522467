@use "global" as *;

.p-menu {
    background: $main-color;
}

.p-menu__img-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.p-menu__img-big {
    // width: 77.27%;
    width: 100%;
    img {
        // aspect-ratio: 255 / 217;
        aspect-ratio: 160 / 141;
        object-fit: cover;
    }
}

.p-menu__img-small {
    // width: 48.48%;
    width: 100%;
    // position: absolute;
    // content: "";
    // left: 50%;
    // margin-top: -30%;
    img {
        aspect-ratio: 160 / 141;
        object-fit: cover;
    }
}


.p-menu__title {
    text-align: center;
    padding-top: myrem(46);
    padding-bottom: myrem(46);
}

.p-menu__text {
    padding-bottom: myrem(32);
}


@include mq(md) {
    .p-menu__img-box {
        // width: 43.25%;
        width: 50%;
    }

    .p-menu__text-box {
        width: 39.25%;
    }

    .p-menu__img-big {
        // max-width: myrem(513);
        width: 100%;
        width: 48%;
        img {
            aspect-ratio: 513 / 434;
            object-fit: cover;
        }
    }

    .p-menu__img-small {
        margin-top: 15%;
        // max-width: myrem(321);
        // max-width: myrem(400);
        width: 48%;
        img {
            aspect-ratio: 321 / 283;
            object-fit: cover;
        }
    }

    .p-menu__title {
        text-align: left;
        padding-top: myrem(96);
        padding-bottom: myrem(46);
    }

    .p-menu__text {
        padding-bottom: myrem(32);
    }

    .p-menu__btn {
        margin-top: myrem(48);
    }
}